@tailwind base;
@import './base.css';
@import './nprogress.css';
@tailwind components;
@import './components.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@tailwind utilities;

/* breakpoints map
xl: desktop
lg: laptop
md: tablet
sm: mobile
/* tailwind-scaling: https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale */

body {
  @apply body-1 text-brand-dark-grey;
}

/*hotjar default feedback button styling starts*/

._hj_feedback_container ._hj-G09L\+__MinimizedWidgetMiddle__container {
  display: none !important;
}

/*hotjar default feedback button styling ends*/

@layer utilities {
  @variants responsive {
    .wrapper {
      @apply mx-auto w-full px-4 lg:px-16 xl:max-w-desktop;
    }
    .wysiwyg a {
      @apply font-roboto underline hover:opacity-75;
    }
    .wysiwyg strong {
      @apply font-bold;
    }
    .wysiwyg ul {
      @apply list-inside list-disc;
    }
    .wysiwyg ol {
      @apply list-inside list-decimal;
    }
    .heading-1,
    .wysiwyg h1 {
      @apply font-roboto text-h1 font-light;
    }

    .heading-2,
    .wysiwyg h2 {
      @apply font-roboto text-h2 font-light;
    }

    .heading-3,
    .wysiwyg h3 {
      @apply font-roboto text-h3 font-light;
    }

    .heading-4,
    .wysiwyg h4 {
      @apply font-roboto text-h4 font-light;
    }

    .heading-mobile-1 {
      @apply font-roboto text-mobileh1 font-light;
    }

    .heading-mobile-2 {
      @apply font-roboto text-mobileh2 font-light;
    }

    .heading-mobile-3 {
      @apply font-roboto text-mobileh3 font-light;
    }

    .heading-mobile-4 {
      @apply font-roboto text-mobileh4 font-light;
    }

    .body-1 {
      @apply font-roboto text-body1 font-light;
    }

    .body-1-bold {
      @apply font-roboto text-body1 font-medium;
    }

    .body-2 {
      @apply font-roboto text-body2 font-light;
    }

    .body-2-bold {
      @apply font-roboto text-body2 font-medium;
    }

    .body-3 {
      @apply font-roboto text-body3 font-light;
    }

    .body-3-bold {
      @apply font-roboto text-body3 font-bold;
    }
    .body-large {
      @apply font-roboto text-body4 font-light;
    }
    .label {
      @apply font-roboto text-label font-normal;
    }

    .label-bold {
      @apply font-roboto text-label font-bold;
    }

    .label-large {
      @apply font-roboto text-labelLarge font-normal;
    }

    .label-large-bold {
      @apply font-roboto text-labelLarge font-bold;
    }
  }
}

.slick-next {
  margin-right: 40px;
  width: unset;
  height: unset;
}

.slick-prev {
  margin-left: 30px;
  z-index: 3;
  width: unset;
  height: unset;
}

.slick-dots {
  bottom: 0px;
  text-align: right;
  max-width: 416px;
}

.slick-dots li {
  margin: unset;
  width: 12px;
}

.slick-dots li button:before {
  font-size: 8px;
  color: #d8d8d8;
}
.slick-dots li.slick-active button:before {
  color: #646464;
}
body .slick-prev::before,
body .slick-next::before {
  display: none;
}
@media (max-width: 1024px) {
  .slick-next {
    margin-right: 30px;
    width: unset;
    height: unset;
  }

  .slick-prev {
    margin-left: 30px;
    z-index: 3;
    width: unset;
    height: unset;
  }
}

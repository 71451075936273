:root {
  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #111;
  --selection: var(--cyan);
  --text-base: #000000;
  --text-primary: #646464;
  --text-secondary: white;
  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --purple: #f81ce5;
  --blue: #0070f3;
  --pink: #ff0080;
  --pink-light: #ff379c;
  --magenta: #eb367f;
  --violet: #7928ca;
  --violet-dark: #4c2889;
  --accent-0: #fff;
  --accent-1: #fafafa;
  --accent-2: #eaeaea;
  --accent-3: #999999;
  --accent-4: #888888;
  --accent-5: #666666;
  --accent-6: #444444;
  --accent-7: #333333;
  --accent-8: #111111;
  --accent-9: #000;
  --dark-grey: #646464;
  --font-sans: 'Helvetica Neue', 'Helvetica', sans-serif;
}

/*
[data-theme='dark'] {
 --primary: #000000;
  --primary-2: #111;
  --secondary: #ffffff;
  --secondary-2: #f1f3f5;
  --hover: rgba(255, 255, 255, 0.075);
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);
  --selection: var(--purple);
  --text-base: white;
  --text-primary: white;
  --text-secondary: black;
  --accent-9: #fff;
  --accent-8: #fafafa;
  --accent-7: #eaeaea;
  --accent-6: #999999;
  --accent-5: #888888;
  --accent-4: #666666;
  --accent-3: #444444;
  --accent-2: #333333;
  --accent-1: #111111;
  --accent-0: #000;
}
*/
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url('/chevronDown.png');
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-repeat: no-repeat;
  outline: 0;
}
